import React, {Component} from 'react';
import '../App.css';
import LocalStorageStore from "./LocalStorageStore";
import {ApplicationContext} from "../data/Context";
import {CACHE_DURATION} from "../data/AppSettings";
import {retrieveFeedData} from "../data/rss-reader.service";
import Taskbar from "./Taskbar";
import {LandingPage} from "./LandingPage";
import Progress from "./Progress";
import {Settings} from "./Settings";
import {Feed} from "./Feed";
import {ArticleWrapper} from "./ArticleWrapper";
import {ArticlesList} from "./ArticlesList";

const store = new LocalStorageStore(window.localStorage);

export default class App extends Component {
    constructor(props) {
        super(props);

        this.loadUserData = () => {
            const feeds = (store.getItem("feeds") || []);
            const userData = {
                feeds,
                articles: feeds.reduce((state, value) => ([...state, ...(store.getItem(`articles-${value.id}`) || [])]), [])
            };
            this.setUserData(userData, true);
            return Promise.resolve();
        };

        this.setUserData = (data, checkFeeds = false) => {
            const projectedState = this.projectState(data);
            this.setState(
                projectedState
                , async () => {
                    this.saveData(data);
                    if (checkFeeds)
                        return this.checkFeeds();
                });
        };

        this.projectState = ({feeds, articles}) => {
            const feedMap = feeds.reduce((state, feed) => ({
                ...state,
                [feed.id]: feed
            }), {});
            return ({
                ...arguments[0],
                feeds,
                articles: articles.map(article => ({
                    ...article,
                    feed: feedMap[article.feedId]
                })),
            });
        };

        this.saveData = (data) => {
            try {
                store.clear();
                store.setItem("feeds", data.feeds.map(feed => ({...feed, articles: undefined})));
                data.feeds.forEach(feed => {
                    store.setItem(`articles-${feed.id}`, data.articles.filter(article => article.feedId === feed.id).map(article => {
                        delete article.feed;
                        return article;
                    }));
                });
            } catch (error) {
                this.pushError(error);
            }
        }

        this.addFeed = () => this.setState({editing: true, ...this.getScrollPosition()});
        this.clearErrors = () => this.setState({errors: []});
        this.clearError = (error) => this.setState((prevState) => ({errors: prevState.errors.filter(e => e !== error)}));
        this.pushError = (error) => this.setState((prevState) => ({errors: prevState.errors.concat([error])}));
        this.setLoading = () => this.setState({loading: true});
        this.clearLoading = () => this.setState({loading: false});
        this.openSettings = () => this.setState({settings: true, ...this.getScrollPosition()});
        this.closeSettings = () => this.setState({settings: false}, () => this.checkFeeds());
        this.toggleSettings = () => (this.state.settings ? this.closeSettings() : this.openSettings());

        this.saveFeed = (feed) => {
            let {feeds} = this.state;
            if (feeds.some(existing => existing.id === feed.id)) {
                return this.pushError(`This is a duplicate feed\n\n${feed.url} (${feed.id}) already exists in your feed list.`);
            }
            if (feed.lastRetreived) {
                delete feed.lastRetreived;
            }
            feeds.splice(feeds.length, 0, feed);
            this.setState({feeds: feeds.slice(), editing: false}, () => {
                const {feeds, articles} = this.state;
                // store.setItem("data", {feeds, articles});
                this.saveData({feeds, articles});
                return this.checkFeeds();
            });
        };

        this.removeFeed = (feed) => {
            const {articles, feeds} = this.state;
            this.setUserData({
                articles,
                feeds: feeds.filter(existing => existing !== feed)
            }, () => this.checkFeeds());
        };

        this.readArticle = (article, scrollTop) => this.setState({"reading": article, scrollTop});
        this.closeArticle = () => this.setState(({scrollTop}) => ({"reading": false, scrollTop}));

        this.checkFeeds = async () => {
            const {feeds, articles} = this.state;

            let retrieved = [];

            for (let feed of feeds) {
                if (feed.lastRetreived && (new Date() - new Date(feed.lastRetreived)) < CACHE_DURATION) {
                    retrieved.push({
                        feed,
                        articles
                    });
                    continue;
                }

                this.setLoading();

                try {
                    let data = await retrieveFeedData(feed);
                    retrieved.push(data);
                } catch (error) {
                    this.pushError(error);
                }

                this.clearLoading();
            }

            let newState = retrieved.reduce((state, value) => ({
                feeds: state.feeds.concat([value.feed]),
                articles: state.articles.concat(value.articles)
            }), {
                feeds: [],
                articles: []
            });

            this.setUserData({
                // ...userData,
                ...newState
            });
        };

        this.state = {
            feeds: [],
            articles: [],
            errors: [],
            userData: {},
            loading: false,
            searchTerm: null,
            loadUserData: this.loadUserData,
            setUserData: this.setUserData,
            clearErrors: this.clearErrors,
            clearError: this.clearError,
            pushError: this.pushError,
            setLoading: this.setLoading,
            clearLoading: this.clearLoading,
            removeFeed: this.removeFeed,
            openSettings: this.openSettings,
            closeSettings: this.closeSettings,
            checkFeeds: this.checkFeeds,
            saveFeed: this.saveFeed,
            toggleSettings: this.toggleSettings,
            readArticle: this.readArticle,
            closeArticle: this.closeArticle,
            addFeed: this.addFeed,
            reading: null,
            settings: null,
            editing: null
        };
    }

    getScrollPosition() {
        const scrollTop = document.body.parentElement.scrollTop;
        return {scrollTop};
    }

    componentDidMount() {
        this.loadUserData()
            .then(() => console.log("loaded"));
        // .then(() => this.checkFeeds());
    }

    renderList() {
        const {
            reading,
            settings,
            editing,
            feeds,
            articles,
            searchTerm,
            checkFeeds,
            closeArticle,
            readArticle,
            scrollTop
        } = this.state;

        if (reading || settings || editing || (feeds.length <= 0))
            return null;

        const articleMap = articles.filter((v, i, a) => a.indexOf(v) === i && a.findIndex(f => f.guid === v.guid) === i)
            .reduce((map, article) => ({
                ...map,
                [article.feedId]: (map[article.feedId] || []).concat([article])
            }), {});

        const feedList = feeds.map(feed => ({
            ...feed, ...feed.meta, articles: (!searchTerm
                ? articleMap[feed.id]
                : articleMap[feed.id].filter(article => (["title", "description", "summary", "link", "source"]
                    .some((prop) =>
                        article[prop] && article[prop].toString().toLocaleLowerCase().includes(searchTerm))))) || []
        }));

        return (
            <ArticlesList feeds={feedList}
                          articles={feedList.reduce((all, list) => all.concat(list.articles.filter(article => article.feed)), [])}
                          checkFeeds={checkFeeds} closeArticle={closeArticle}
                          readArticle={readArticle} scrollTop={scrollTop}/>
        );

        // return feedList.map(feed => <ArticleList key={feed.id} feed={feed} articles={feed.articles}
        //                                          checkFeeds={checkFeeds} closeArticle={closeArticle}
        //                                          readArticle={readArticle} scrollTop={scrollTop}
        //                                          containerRef={this.containerRef}/>);
    }

    renderLandingPage() {
        const {reading, settings, editing, feeds, articles, saveFeed, addFeed} = this.state;

        if (reading || settings || editing || (feeds.length > 0) || (articles.length > 0))
            return null;

        return <LandingPage saveFeed={saveFeed} addFeed={addFeed}/>;
    }

    render() {
        const {loading, toggleSettings, settings, reading, feeds, editing, addFeed} = this.state;
        return (
            <div className="container-fluid">
                <ApplicationContext.Provider value={this.state}>
                    <Taskbar toggleSettings={toggleSettings}
                             isSettingsOpen={settings}
                             isEditing={editing}
                             addFeed={addFeed}
                             enabled={!reading}
                             hasFeeds={feeds.length > 0}/>
                    <div className="container">
                        {loading && <Progress className="fa-5x ml-auto mr-auto"/>}
                        {this.renderLandingPage()}
                        {this.renderReadArticle()}
                        {this.renderList()}
                        {this.renderSettings()}
                        {this.renderEditFeed()}
                    </div>
                </ApplicationContext.Provider>
            </div>
        )
    }

    renderSettings() {
        const {settings, closeSettings, feeds, removeFeed} = this.state;
        return (settings && <Settings feeds={feeds} removeFeed={removeFeed} close={closeSettings}/>);
    }

    renderEditFeed() {
        const {editing, saveFeed} = this.state;
        return (editing && <Feed onSave={saveFeed} close={() => this.setState({editing: false})}/>);
    }

    renderReadArticle() {
        const {reading, closeArticle} = this.state;
        return (reading && <ArticleWrapper closeArticle={closeArticle} article={reading}/>)
    }
}