import React, {Component} from "react";
import uuid from "uuid/v4";
import popularFeeds from "../feeds.json";

export class Feed extends Component {
    constructor() {
        super();
        this.state = {name: "", url: "", parser: 0};
    }

    updateField(e) {
        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        });
    }

    save(e) {
        e.preventDefault();

        let {url} = this.state;
        let {onSave} = this.props;

        let feed = this.isUrl(url)
            ? this.getFeedAddressInfo()
            : this.getFeedKeywordInfo();


        if (feed.url.toLowerCase().startsWith("https://news.google.com")) {
            feed.parser = 2;
        }
        if (feed.url.toLowerCase().startsWith("https://hnrss.org")) {
            feed.parser = 1;
        }

        onSave(feed);
    }

    getFeedAddressInfo() {
        return ({
            id: uuid(),
            feedCategory: "address",
            ...this.state
        });
    }

    isUrl(value) {
        return /\b(https?|ftp|file):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]/.test(value);
    }

    getFeedKeywordInfo() {
        let {url: keyword} = this.state;
        let feed = {
            ...this.state,
            id: uuid(),
            name: keyword,
            feedCategory: "keyword",
            url: `https://news.google.com/news/rss/search/q/${keyword}?hl=en&gl=US&ned=us`
        };
        return feed;
    }

    render() {
        let {url} = this.state;
        let {onSave, close} = this.props;

        return (
            <div>
                <div className="page-header">
                    <h2>Manual Feed configuration <small>specify address and name of feed</small></h2>
                </div>
                <form onSubmit={(e) => this.save(e)}>
                    <div className="input-group mb-3">
                        <input type="text" value={url} onChange={e => this.updateField(e)} className="form-control"
                               name="url" id="url" placeholder="Feed URL or Keyword"/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="clearFeed"
                                    onClick={() => this.setState({url: ""})}>
                                <i className="fa fa-trash mr-2"/>
                                Clear
                            </button>
                        </div>
                    </div>

                    <button type="submit" disabled={!url || url.trim().length <= 0}
                            className="btn btn-primary">Add {this.isUrl(url) ? `feed` : `keyword search`}</button>
                    <button type="button" onClick={close} className="btn btn-link ml-2">Nevermind</button>
                </form>
                <hr/>
                <div className="page-header">
                    <h2>Or choose from a preconfigured feed...</h2>
                </div>
                <div className="list-group">
                    {popularFeeds.map(feed => <a className="list-group-item" key={feed.id} href="/#" onClick={() => {
                        onSave({...feed, feedCategory: "address"});
                        close()
                    }}>
                        <i className="fa fa-plus-circle mr-2"/>{feed.name}
                    </a>)}
                </div>
            </div>
        )
    }
}