import React from "react";
import "./Settings.css";

export const Settings = (props) => {
    const {feeds, close, removeFeed} = props;

    if(feeds.length <= 0){
        close();
    }

    return (
        <div className="settings-page">
            <h4 className="settings-header">Settings</h4>
            <div className="main">
                <ul className="list-group">
                    {feeds.map(feed => (<li key={feed.id} className="list-group-item">
                        <span className="settings-feed-url">
                            {feed.url}
                        </span>
                        <div className="btn-group ml-auto btn-group-sm">
                            <button type="button" onClick={() => removeFeed(feed)} className="btn btn-danger">
                                <i className="fa fa-trash fa-trash-o mr-2"></i>Remove
                            </button>
                        </div>
                    </li>))}
                </ul>
            </div>
            <footer className="settings-footer">
                <div className="container">
                    <button className="btn btn-primary mt-2 w-100" onClick={close}>Back</button>
                </div>
            </footer>
        </div>
    );
}