import React from 'react';
import classnames from "classnames";

export default class Taskbar extends React.Component {
    render() {
        let {addFeed, toggleSettings, isSettingsOpen, enabled, isEditing, hasFeeds} = this.props;
        return (
            <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top">
                <div className="collapse navbar-collapse p-2">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <button disabled={!enabled || isSettingsOpen} onClick={addFeed} className="btn btn-outline-success btn-sm"><i className="fa fa-rss text-danger mr-2"></i>Add Feed</button>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <button disabled={!enabled || isEditing || !hasFeeds} onClick={toggleSettings} className={classnames("btn btn-sm btn-link", {"btn-info": isSettingsOpen})}><i className="fa fa-list mr-2"></i>Settings</button>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}