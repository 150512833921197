import React, {Component} from "react";
import cheerio from "cheerio";
import youtubeIcon from "../../resources/icons/24-24-youtube.png";
import moment from "moment";
import classNames from "classnames";

const Highlighter = ({keyword, content}) => (keyword && keyword.length ? (<span>
    {content.split(new RegExp(`(${keyword})`, "ig"))
        .map((match, i) => <span
            key={`search-match-${i}`}
            className={classNames({"search-highlight": keyword.toLowerCase() === match.toLowerCase()})}>{match.toString()}</span>)}
</span>) : (content));

class GoogleFeedItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reading: false
        };

        this.cardRef = React.createRef();
    }

    toggleReadArticle(e) {
        e && e.preventDefault();
        const {readArticle, item: article} = this.props;
        readArticle(article);
        // this.setState((prevState) => ({
        //     reading: !prevState.reading
        // }), () => this.scrollToArticle());
    }

    render() {
        let {item, keyword} = this.props;
        if (!item || !item.description) {
            return null;
        }
        // console.log(item.description);
        const $ = cheerio.load(item.description);
        const itemData = loadGoogleDescriptionData($);
        const headline = itemData.headlines.shift() || loadFuckedUpGoogleNewsData($);
        headline.title = headline.title.replace(/view full coverage on google news/ig, "");

        const longDate = moment(item.date).format('MMMM Do YYYY, h:mm:ss a');
        const relativeTime = moment(item.date).fromNow();
        const {reading} = this.state;
        const {feed} = item;

        return (
            <div className="card" id={`article-card-${item.guid}`} ref={this.cardRef}>
                <div className="d-flex flex-row">
                    {reading && (<div className="card-header p-0 border-0">
                        <button onClick={(e) => this.toggleReadArticle(e)}
                                className="btn btn-info p-1">{(Array.from({length: 3}).map((e, i) => <i
                            className="fa fa-chevron-left" key={i}/>))}</button>
                    </div>)}
                    <div className="card-body">
                        <h6 className="card-title">
                            <a href={`https://www.google.com/search?q="${headline.title}"`} target="_blank"
                               rel="noopener noreferrer"
                               title={`https://www.google.com/search?q="${headline.title}"`}><i
                                className="mr-2 fa fa-external-link-square"/>
                            </a>
                            <a href={item.link} className="p-0 m-0"
                               onClick={(e) => this.toggleReadArticle(e)}><Highlighter
                                content={headline.title} keyword={keyword || ""}/>
                            </a>
                            <span className="badge badge-dark badge-pill"
                                  style={{marginLeft: "5px"}} title={headline.source}>
                                    <Highlighter content={headline.source}
                                                 keyword={keyword || ""}/>{this.renderMediaIcon(item)}
                            </span>
                            <a href={headline.link} title={headline.link} className="text-muted small" target="_blank"
                               rel="noopener noreferrer">
                                Open Original
                            </a>
                        </h6>
                        {/*{reading && <ReadArticle url={headline.link} onLoaded={() => this.scrollToArticle()}/>}*/}
                        {itemData.headlines.filter(h => h.source).map((headline, i) => (
                            <span className="badge badge-dark badge-pill" key={`headline-pill-${i}`}>
                                <a key={headline.link} href={headline.link} target="_blank" rel="noopener noreferrer"
                                   title={headline.link}
                                   className="mx-1">
                                    <Highlighter content={headline.source}
                                                 keyword={keyword || ""}/>{this.renderMediaIcon(headline)}
                                </a>
                                <a href={`https://www.google.com/search?q=${headline.link}`} target="_blank"
                                   className="mx-1" rel="noopener noreferrer"><i
                                    className="fa fa-external-link-square"/></a>
                            </span>
                        ))}
                        <footer className="blockquote-footer"><span
                            className={classNames("badge badge-pill text-white", {
                                "googlenews-avatar": feed.feedCategory === "address",
                                "keyword-avatar": feed.feedCategory === "keyword"
                            })}>{item.meta.title}</span> | <span
                            title={longDate}>{relativeTime} | {longDate}</span></footer>
                    </div>
                </div>
                <div className="card-footer w-100 text-muted border-0" style={{padding: 0, height: "4px"}}></div>
            </div>
        );
    }

    renderMediaIcon(item) {
        // google now obscures all rss results through amp/link-tracking making the original link unusable! so we have to check the source.
        // the source url will contain https://www.youtube.com when the target is a video even though the source element data will contain the author IE: Guardian News
        if (item.link.toLocaleLowerCase().includes("youtube.com") ||
            (item.source && item.source.url && item.source.url.toLocaleLowerCase().includes("youtube"))) {
            return <img style={{marginLeft: "5px", marginBottom: "2px"}} src={youtubeIcon} alt={item.link}/>
        }
    }

    renderImageOrAvatar(item, itemData) {
        const image = itemData.image || (item.enclosures || []).find(enclosure => enclosure.type === "image");
        return image ?
            <div className="card-img">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={image.url} alt="Article"/>
                </a>
            </div>
            :
            <div className="card-img">
                <div className="avatar googlenews-avatar">
                    GN
                </div>
            </div>;
    }

    scrollToArticle() {
        this.cardRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }
}

function loadFuckedUpGoogleNewsData($) {
    return {
        title: $("a").text(),
        link: $("a").attr("href"),
        source: $("font").text()
    }
}

function loadGoogleDescriptionData($) {
    return {
        image: $("img").attr("src"),
        headlines: $("li").map((i, e) => ({
            title: $(e).find("a").text(),
            link: $(e).find("a").attr("href"),
            source: $(e).find("font").text()
        })).get()
    };
}

export default GoogleFeedItem;