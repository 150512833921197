import React from 'react';
import ReactDOM from 'react-dom';
import "./vendor";
import './index.css';
import App from "./components/App";

import registerServiceWorker from './registerServiceWorker';

import LocalStorageStore from "./components/LocalStorageStore";

ReactDOM.render(<App store={new LocalStorageStore(window.localStorage)} />, document.getElementById('root'));
registerServiceWorker();
