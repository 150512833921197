class LocalStorageStore {
    constructor(store){
        this.store = store;
    }

    getItem(key) {
        let value = this.store.getItem(key);
        try{
            value = JSON.parse(value);
        }catch(error){
            console.warn(`Key at ${key} is not a JSON construct.`);
        }
        return value;
    }

    setItem(key, value){
        this.store.setItem(key, JSON.stringify(value));
    }

    removeItem = key => this.store.removeItem(key);
    clear = () => this.store.clear();
}

export default LocalStorageStore;