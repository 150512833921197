import FeedParser from "feedparser";
import Stream from "stream";
import {CORS_PROXY} from "./AppSettings";

const NOOP = () => {};

export const retrieveFeedData = async (feed) => {
    let {id, url} = feed;
    if (!url.startsWith(CORS_PROXY)) {
        url = CORS_PROXY + url;
    }

    let feedparser = new FeedParser();
    let response = await fetch(url);

    let body = await response.text();

    let stream = new Stream.Readable();

    let articles = [];

    return new Promise(((resolve, reject) => {
        stream._read = NOOP;
        stream.push(body);
        stream.destroy();

        feedparser.on("meta", meta => {
            feed.meta = meta;
            feed.lastRetreived = new Date();
        });

        feedparser.on("error", err => {
            reject(err.message);
        });

        feedparser.on("readable", () => {
            let item = feedparser.read();
            if (item !== null) {
                item.feedId = id;
                articles.push(item);
            }
        });

        feedparser.on("end", () => {
            resolve({feed, articles});
        });

        stream.pipe(feedparser);
    }));
}