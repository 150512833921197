import React, {useEffect} from "react";
import allParsers from "./parsers";

export const ArticlesList = ({
                                 articles,
                                 readArticle,
                                 closeArticle,
                                 scrollTop
                             }) => {

    useEffect(() => {
        if (scrollTop >= 0) {
            window.requestAnimationFrame(() => {
                document.body.parentElement.scrollTop = scrollTop;
            });
        }
    });

    const saveScrollPositionAndReadArticle = (article) => {
        const scrollTop = document.body.parentElement.scrollTop;
        readArticle(article, scrollTop);
    };

    articles.sort((a, b) => new Date(b.date) - new Date(a.date));

    if(!articles.length){
        return ""
    }

    return (
        <div className="article-list">
            {articles.map(article => (
                <div
                    key={article.guid}>
                    <div className="feed-item">
                        {React.createElement(allParsers[article.feed.parser].component, {
                            item: article,
                            readArticle: saveScrollPositionAndReadArticle,
                            closeArticle
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};