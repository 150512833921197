import React from "react";
import {Article} from "./Article";


export const ArticleWrapper = (props) => {
    const {closeArticle, article} = props;

    return (
        <div className="read-article">
            <div className="fixed-parent">
                <button onClick={closeArticle} className="btn btn-primary fixed-top w-100"><i className="fa fa-chevron-circle-left mr-2" />Close</button>
            </div>
            <div className="article-content mb-5">
                <Article article={article} url={article.link} />
            </div>
            <div className="fixed-parent">
                <button onClick={closeArticle} className="btn btn-primary fixed-bottom w-100"><i className="fa fa-chevron-circle-left mr-2" />Close</button>
            </div>
        </div>
    );
}