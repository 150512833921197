import React from "react";
import popularFeeds from "../feeds.json";

const randomFeed = popularFeeds[Math.floor(Math.random() * popularFeeds.length)];

export const LandingPage = ({addFeed, saveFeed}) => (
    <div className="jumbotron">
        <h1>D'oh, you don't have any subscriptions!</h1>
        <h4>Try subscribing to a feed by specifying the url or by selecting from the popular feed
            list.</h4>
        <h4>Why not try
            <button className="btn btn-info ml-3 mr-2"
                    onClick={() => saveFeed({...randomFeed})}><i className="fa fa-rss"></i> Subscribe to {randomFeed.name}
            </button>
            or
            <button className="btn btn-success ml-2"
                       onClick={() => addFeed()}><i className="fa fa-plus-circle"></i> Add a new feed
            </button>
        </h4>
    </div>
);