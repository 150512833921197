import unfluff from "unfluff";
import Progress from "./Progress";
import React, {useEffect, useState} from "react";
import {CORS_PROXY} from "../data/AppSettings";

const DEFAULT_STATE = {};

export const Article = (props) => {
    const [loading, setLoading] = useState(true);
    const [article, setArticle] = useState(DEFAULT_STATE);
    const [attemptCount, setAttemptCount] = useState(0);

    useEffect(() => {
        const {url} = props;

        const resolveUrl = (url) => {
            return url.toLowerCase().startsWith("https://news.google.com/rss/articles")
                ? fetch(CORS_PROXY + url)
                    .then(response => response.text())
                    .then(googleEvil => /data-n-au="(.*?)"/.exec(googleEvil)[1]) // Parse the actual article url from the injected evil straight from google
                : Promise.resolve(url)
        }

        const loadArticle = () => {
            if (attemptCount > 1) {
                return Promise.reject("unable to retrieve article data");
            }
            return resolveUrl(url)
                .then(url => fetch(CORS_PROXY + url))
                .then(response => response.text())
                .then(unfluff)
                .then(data => {
                    setLoading(false);
                    setArticle(data);
                }).catch(error => setAttemptCount(attemptCount + 1));
        };

        if (DEFAULT_STATE === article)
            loadArticle()
                .catch(console.error);
    });

    let {text, image, title, canonicalLink, publisher, description} = article;


    return (
        <div className="unfluff-content bg-dark">
            {loading && (<div><Progress/> Loading article...</div>)}
            {image && <img className="article-image" src={image} alt={title}/>}
            <h2><a href={canonicalLink}>{title}</a></h2>
            <blockquote>{description}<span className="blockquote-footer">{publisher}</span></blockquote>
            <pre className="unfluff-text text-light">
                {text}
            </pre>
        </div>
    );
}