import React, {Component} from "react";
import cheerio from "cheerio";
import moment from "moment";

class HNFeedItem extends Component {
    render() {
        let {item} = this.props;
        if (!item || !item.description) {
            return null;
        }
        const $ = cheerio.load(item.description);
        const itemData = loadHNDescriptionData($);

        const longDate = moment(item.date).format('MMMM Do YYYY, h:mm:ss a');
        const relativeTime = moment(item.date).fromNow();

        return (
            <div className="card">
                <div className="d-flex flex-row">
                    <div className="card-body">
                        <h6><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></h6>
                        <br/>
                        <a href={itemData.threadUrl} target="_blank" rel="noopener noreferrer">{itemData.comments}</a>
                        <footer className="blockquote-footer"><span
                            className="badge badge-pill hackernews-avatar text-white">{item.meta.title}</span> | <span
                            title={longDate}>{relativeTime} | {longDate}</span></footer>
                    </div>
                </div>
                <div className="card-footer w-100 text-muted border-0" style={{padding: 0, height: "4px"}}></div>
            </div>
        );
    }
}

function loadHNDescriptionData($) {
    return {
        articleUrl: $("a").first().text(),
        threadUrl: $("a").last().text(),
        points: $($("p")[2]).text(),
        comments: $($("p")[3]).text()
    };
}

export default HNFeedItem;