import React, {Component} from "react";

class GenericFeedItem extends Component {
    render() {
        let {item} = this.props;

        let imageSource = item.image.url ? item.image.url : item["news:image"] ? item["news:image"]["#"] : null;

        return (
            <div className="card">
                <div className="d-flex flex-row">
                    {
                        imageSource && (<div className="card-header border-0">
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                <img src={imageSource} alt="Article"/>
                            </a>
                        </div>)
                    }
                    <div className="card-body">
                        <h6><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>
                            <small>{item["News:Source"]}</small>
                        </h6>
                        <p>{item.summary}</p>
                    </div>
                </div>
                <div className="card-footer w-100 text-muted border-0" style={{padding: 0, height: "4px"}}></div>
            </div>
        );
    }
}

export default GenericFeedItem;